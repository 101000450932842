






























































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import WithHeaderLayout from "@/layouts/WithHeaderLayout.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import PickDateTimeRange from "@/components/pickDateTime/PickDateTimeRange.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import User from "@/models/User"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { memoContainer } from "@/containers/MemoContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { Meeting } from "@/models/Meeting"
import DateTime from "@/models/DateTime"
import { required } from "@/utilities/Validation"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"

interface State {
  isFormValid: boolean
  isLoadingCreate: boolean
  endTime: DateTime
  startTime: DateTime
  subject: string
  body: object | null
  bodyHtml: string
  members: Array<User>
  users: Array<User>
}

export default defineComponent({
  props: {
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    subject: {
      type: String,
    },
    body: {
      type: Object,
    },
  },
  components: {
    TextField,
    RichEditor,
    PickDateTimeRange,
    I18nFormattedMessage,
    WithHeaderLayout,
    DefaultDialog,
    UsersAutoComplete,
  },
  setup(props, { emit }) {
    const { getContext, getMembers } = teamsContextContainer.useContainer()
    const { createEventAsync } = memoContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()
    const state = reactive<State>({
      isFormValid: true,
      isLoadingCreate: false,
      endTime: new DateTime(),
      startTime: new DateTime(),
      subject: "",
      body: null,
      bodyHtml: "",
      members: [],
      users: [],
    })

    const userCount = computed<number>(() => {
      const users = state.users.filter(u => u.userId !== undefined)
      return users.length
    })

    watch(
      () => props.body,
      newValue => {
        state.body = newValue ?? null
      }
    )

    watch(
      () => props.subject,
      newValue => {
        state.subject = newValue || ""
      }
    )

    const onOk = async () => {
      state.isLoadingCreate = true
      await createEventAsync(
        {
          subject: state.subject,
          location: "",
          isTeams: false,
          startTime: state.startTime,
          endTime: state.endTime,
          users: state.users,
        } as Meeting,
        state.bodyHtml
      )
      state.isLoadingCreate = false
      showSuccessMessage("イベントを作成しました")
      emit("cancel")
    }

    const onCancel = () => {
      emit("cancel")
    }

    const changeBody = (currentHtml: string) => {
      state.bodyHtml = currentHtml
    }

    const updateTime = (key: "startTime" | "endTime", newValue: string) => {
      state[key] = new DateTime(newValue)
    }

    const eventRule = [
      (val: { startTime: DateTime; endTime: DateTime }) => {
        if (val.endTime?.isBefore(val.startTime, "minutes")) {
          return "終了日時が開始日時より前に設定されています"
        }
        if (val.startTime?.isBefore(new DateTime(new Date()), "minutes")) {
          return "過去日時を設定できません"
        }
        if (val.endTime?.isBefore(new DateTime(new Date()), "minutes")) {
          return "過去日時を設定できません"
        }
        return true
      },
    ]

    const subjectRule = [required]

    onMounted(async () => {
      const context = await getContext()
      state.members = (await getMembers()).map(m => new User(m))
      state.subject = props.subject || ""
      state.users = state.members.filter(m => m.userId === context.userObjectId)
    })

    return {
      state,
      userCount,
      onOk,
      onCancel,
      eventRule,
      subjectRule,
      updateTime,
      changeBody,
    }
  },
})
