export function escapeHtml(string: string) {
  return string.replace(/[&'`"<>]/g, match => {
    if (match === "&") return "&amp;"
    if (match === "'") return "&#x27;"
    if (match === "`") return "&#x60;"
    if (match === '"') return "&quot;"
    if (match === "<") return "&lt;"
    if (match === ">") return "&gt;"
    return match
  })
}

export function unescapeHtml(string: string) {
  return string.replace(
    /&amp;|&#58;|&#x27;|&#x60;|&quot;|&lt;|&gt;/g,
    match => {
      if (match === "&#58;") return ":"
      if (match === "&amp;") return "&"
      if (match === "&#x27;") return "'"
      if (match === "&#x60;") return "`"
      if (match === "&quot;") return '"'
      if (match === "&lt;") return "<"
      if (match === "&gt;") return ">"
      return match
    }
  )
}
