





















































import {
  defineComponent,
  PropType,
  reactive,
  watch,
} from "@vue/composition-api"
import CalendarMenu from "./CalendarMenu.vue"
import Dropdown, { Option } from "@/components/dropdowns/Dropdown.vue"
import DateTime from "@/models/DateTime"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"

type ruleFunction = (value: {
  startTime: DateTime
  endTime: DateTime
}) => boolean | string

interface TimeOption extends Option {
  text: string
  value: string
  duration: number
}

interface State {
  date: string
  errorMessage: string | boolean
  startTime: TimeOption
  endTime: TimeOption
  startTimeItems: Array<TimeOption>
  endTimeItems: Array<TimeOption>
}

export default defineComponent({
  components: {
    CalendarMenu,
    Dropdown,
    I18nFormattedMessage,
  },
  props: {
    update: {
      type: DateTime,
    },
    disabled: Boolean,
    rules: Array as PropType<ruleFunction[]>,
  },

  setup(props, { emit }) {
    const defaultTimeItems = _getDefaultTimeItems()
    const startTimeIndex = _getDefaultStartTimeIndex(defaultTimeItems)

    const state = reactive<State>({
      date: "",
      errorMessage: "",
      startTime: _getDefaultTimeItems()[startTimeIndex],
      endTime: _getDefaultTimeItems()[startTimeIndex + 1],
      startTimeItems: defaultTimeItems,
      endTimeItems: _getEndTimeItems(
        defaultTimeItems[startTimeIndex].value as string
      ),
    })

    const calcError = (start: Date, end: Date) => {
      if (!props.rules) return true
      let ret: boolean | string = true
      for (const rule of props.rules) {
        ret = rule({
          startTime: new DateTime(start),
          endTime: new DateTime(end),
        })
      }
      return ret
    }

    //デフォルトの終了時刻リスト
    const defaultEndTimeItems = _getEndTimeItems()

    const onTimeChanged = (
      type: "startTime" | "endTime",
      timeValue: string
    ) => {
      if (!type) return

      if (type === "endTime") {
        state.endTime.text = timeValue
      }

      const date = state.date === "" ? new Date() : new Date(state.date)
      const dateTime = _conmibeTimeToDate(type, date, timeValue)
      emit(
        `${type === "startTime" ? "startTimeInput" : "endTimeInput"}`,
        dateTime
      )
      const endTime = _conmibeTimeToDate("endTime", date, state.endTime.value)
      const startTime = _conmibeTimeToDate(
        "startTime",
        date,
        state.startTime.value
      )
      state.errorMessage = calcError(startTime, endTime)
    }

    const onDateChanged = (date: string) => {
      state.date = date

      const newStartTime = _conmibeTimeToDate(
        "startTime",
        new Date(date),
        state.startTime.value as string
      )

      const newEndTime = _conmibeTimeToDate(
        "endTime",
        new Date(date),
        state.endTime.value as string
      )
      emit("startTimeInput", newStartTime)
      emit("endTimeInput", newEndTime)
      state.errorMessage = calcError(newStartTime, newEndTime)
    }

    const emitDefaultDateTime = () => {
      const defaultStartTime = _conmibeTimeToDate(
        "startTime",
        new Date(),
        defaultTimeItems[startTimeIndex].value
      )
      const defaultEndTime = _conmibeTimeToDate(
        "endTime",
        new Date(),
        defaultTimeItems[startTimeIndex + 1].value
      )

      emit("startTimeInput", defaultStartTime)
      emit("endTimeInput", defaultEndTime)
      state.errorMessage = calcError(defaultStartTime, defaultEndTime)
    }

    emitDefaultDateTime()

    watch(
      () => props.update,
      newValue => {
        if (newValue) onDateChanged(newValue.toDateStringSlash())
      }
    )

    watch(
      () => state.startTime.value,
      selectedTime => {
        const startTimeIndex = state.startTimeItems.findIndex(
          t => t.value === selectedTime
        )
        const endTimeIndex = state.startTimeItems.findIndex(
          t => t.value === state.endTime.value
        )

        //終了時刻より前の時間が選択された場合は、選択済み終了時刻は変更せず、終了時刻のリストのみ更新する
        if (startTimeIndex < endTimeIndex) {
          const changedTimeItems = _sliceEndTimeItems(
            selectedTime,
            defaultEndTimeItems
          )

          state.endTimeItems = changedTimeItems
        } else {
          //終了時刻より後の時間が選択された場合は、終了時刻と終了時刻リストも更新する
          //終了時刻が開始時刻の30分後になるように設定する

          const changedTimeItems = _sliceEndTimeItems(
            selectedTime,
            defaultEndTimeItems
          )

          state.endTimeItems = changedTimeItems
          state.endTime = state.endTimeItems[0]

          const date = state.date === "" ? new Date() : new Date(state.date)
          const dateTime = _conmibeTimeToDate(
            "endTime",
            date,
            state.endTimeItems[0].value
          )

          emit("endTimeInput", dateTime)
          const startTime = _conmibeTimeToDate(
            "startTime",
            date,
            state.startTimeItems[startTimeIndex].value
          )
          state.errorMessage = calcError(startTime, dateTime)
        }
      }
    )

    return {
      state,
      onTimeChanged,
      onDateChanged,
    }
  },
})

/**
 * 時間選択肢（select-list)を作成
 */
function _getDefaultTimeItems(): Array<TimeOption> {
  const times = [...Array(48)].map((_, i) => {
    const hour = Math.floor(i / 2)
    if (i % 2 === 0) {
      if (hour < 10) {
        return { text: `0${hour}:00`, value: `0${hour}:00` }
      } else {
        return { text: `${hour}:00`, value: `${hour}:00` }
      }
    } else {
      if (hour < 10) {
        return { text: `0${hour}:30`, value: `0${hour}:30` }
      } else {
        return { text: `${hour}:30`, value: `${hour}:30` }
      }
    }
  })

  const timeItems = times.map(t => {
    return { ...t, duration: 0 }
  })

  return timeItems
}

function _getEndTimeItems(time?: string) {
  const times = _getDefaultTimeItems()
  times.splice(0, 1)
  times.push({ text: "00:00", value: "00:00", duration: 0 })
  if (time === undefined) return times
  return _sliceEndTimeItems(time, times)
}

/**
 * 終了時刻の時間選択リストを切り抜く（開始時刻以降の時間選択のみ可能とするため）
 */
function _sliceEndTimeItems(
  time: string,
  defaultTimeItems: Array<TimeOption>
): Array<TimeOption> {
  const index = defaultTimeItems.findIndex(t => t.value === time)

  let durationLength = 0

  //開始時間で00:00が選択された場合はindexが47となる。
  //この場合には、終了時刻はdefaultTimeItemsを丸コピーしたリストを使う。
  return index === 47
    ? defaultTimeItems.slice(0).map(t => {
        durationLength += 0.5
        return { ...t, duration: durationLength }
      })
    : defaultTimeItems.slice(index + 1, defaultTimeItems.length + 1).map(t => {
        durationLength += 0.5
        return { ...t, duration: durationLength }
      })
}

/**
 * 日付と時間を結合
 */
function _conmibeTimeToDate(
  type: "startTime" | "endTime",
  date: Date,
  timeValue: string
) {
  const ret = new Date(date.getTime())
  //終了時刻が00時00分の場合は、日付を一日ずらす
  if (type && type === "endTime" && timeValue === "00:00") {
    ret.setDate(date.getDate() + 1)
  }

  const timeSplit = timeValue.split(":")
  ret.setHours(Number(timeSplit[0]))
  ret.setMinutes(Number(timeSplit[1]))
  ret.setSeconds(0)
  return ret
}

/**
 * デフォルトの開始時間index返す
 * 現在時刻より遅い時間をデフォルトの開始時間として表示するため。
 */

function _getDefaultStartTimeIndex(
  defaultTimeItems: Array<TimeOption>
): number {
  const nowHour = new Date().getHours()
  const nowMinutes = new Date().getMinutes()

  const index = defaultTimeItems.findIndex(
    t => Number(t.value.split(":")[0]) === nowHour
  )

  //xx:30からは次のindexを表示。例）現在時刻が09:32の場合は開始時刻を10:00と表示する
  if (nowMinutes > 29) {
    return index + 1
  } else {
    return index
  }
}
