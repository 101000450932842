









































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import WithHeaderLayout from "@/layouts/WithHeaderLayout.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import Loading from "@/components/Loading.vue"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { escapeHtml, unescapeHtml } from "@/utilities/Escape"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  isFormValid: boolean
  isLoadingCreate: boolean
  openDialog: boolean
  body: object | null
  currentHtml: string | null
  bodyHtml: string
}

export default defineComponent({
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
    meetingStructure: {
      type: MeetingStructure,
      required: true,
    },
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    body: {
      type: Object,
    },
  },
  components: {
    Loading,
    RichEditor,
    I18nFormattedMessage,
    WithHeaderLayout,
    DefaultDialog,
  },
  setup(props, { emit }) {
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()
    const {
      updateSharepointItem,
      getOrCreateCarryOverMemo,
    } = meetingContainer.useContainer()
    const state = reactive<State>({
      isFormValid: true,
      isLoadingCreate: false,
      openDialog: false,
      body: null,
      currentHtml: null,
      bodyHtml: "",
    })

    const reset = async () => {
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      state.currentHtml = unescapeHtml(
        await getOrCreateCarryOverMemo(
          project.siteId,
          context.entityId,
          props.meetingStructure
        )
      )
    }

    watch(
      () => props.body,
      newValue => {
        state.body = newValue ?? null
      }
    )

    watch(
      () => props.isOpenDialog,
      async isOpen => {
        if (isOpen) {
          await reset()
        } else {
          state.currentHtml = null
        }
      }
    )

    const onOk = async () => {
      state.isLoadingCreate = true
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      await updateSharepointItem(
        project.siteId,
        context.entityId,
        props.meetingStructure,
        { memo: (state.currentHtml ?? "") + escapeHtml(state.bodyHtml) }
      )
      state.isLoadingCreate = false
      showSuccessMessage("繰り越しを登録しました")
      emit("cancel")
    }

    const onCancel = () => {
      emit("cancel")
    }

    const changeBody = (currentHtml: string) => {
      state.bodyHtml = currentHtml
    }

    const onCancelCurrentMemo = () => {
      state.openDialog = false
    }

    const onOkCurrentMemo = async () => {
      emit("insert", state.currentHtml)
      state.openDialog = false
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      await updateSharepointItem(
        project.siteId,
        context.entityId,
        props.meetingStructure,
        { memo: "" }
      )
    }

    onMounted(async () => {
      await reset()
      state.openDialog =
        !!state.currentHtml &&
        !props.readonly &&
        props.meeting.endTime.isAfter(new Date(), "minutes")
    })

    return {
      state,
      onOk,
      onCancel,
      changeBody,
      onOkCurrentMemo,
      onCancelCurrentMemo,
    }
  },
})
