




































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import WithHeaderLayout from "@/layouts/WithHeaderLayout.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import PickDateTime from "@/components/pickDateTime/PickDateTime.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import User from "@/models/User"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { notificationContainer } from "@/containers/NotificationContainer"
import { alertContainer } from "@/containers/AlertContainer"
import DateTime from "@/models/DateTime"
import { Meeting } from "@/models/Meeting"
import { i18nContainer } from "@/containers/I18nContainer"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"

interface State {
  isLoadingCreate: boolean
  isFormValid: boolean
  notificationDate: DateTime
  members: Array<User>
  users: Array<User>
  body: object | string | null
  bodyHtml: string
}

export default defineComponent({
  props: {
    meeting: {
      type: Meeting,
      required: true,
    },
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    body: {
      type: Object,
    },
  },
  components: {
    RichEditor,
    PickDateTime,
    I18nFormattedMessage,
    WithHeaderLayout,
    DefaultDialog,
    UsersAutoComplete,
  },
  setup(props, { emit }) {
    const { getContext, getMembers } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()
    const { createNotification } = notificationContainer.useContainer()
    const { formatMessage } = i18nContainer.useContainer()
    const state = reactive<State>({
      isLoadingCreate: false,
      isFormValid: false,
      notificationDate: new DateTime(new Date()),
      members: [],
      users: [],
      body: null,
      bodyHtml: "",
    })

    const userCount = computed<number>(() => {
      const users = state.users.filter(u => u.userId !== undefined)
      return users.length
    })

    watch(
      () => props.body,
      newValue => {
        state.body = newValue ?? null
      }
    )

    const reset = async () => {
      const context = await getContext()
      state.members = (await getMembers()).map(m => new User(m))
      state.notificationDate = new DateTime(new Date())
      state.users = state.members.filter(m => m.userId === context.userObjectId)
    }

    watch(
      () => props.isOpenDialog,
      () => {
        reset()
      }
    )

    const disallowPast = [
      (value: DateTime) => {
        if (value.isBefore(new DateTime(new Date()), "minutes"))
          return formatMessage({
            id: "past",
            defaultMessage: "過去日時を設定できません",
          })
        return true
      },
    ]

    const onOk = async () => {
      state.isLoadingCreate = true
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      const target = document.querySelector(
        "#notification-target .ProseMirror"
      ) as HTMLElement
      if (target) {
        state.body = state.bodyHtml
        const context = await getContext()
        try {
          await createNotification(
            props.meeting.id,
            project.siteId,
            context,
            target,
            state.notificationDate,
            state.users
          )
        } finally {
          state.isLoadingCreate = false
        }
        showSuccessMessage("通知を作成しました")
      }
      emit("cancel")
    }

    const onCancel = () => {
      emit("cancel")
    }

    const isFormValid = computed(() => {
      const ret = state.users.length > 0
      return ret
    })

    const changeBody = (currentHtml: string) => {
      state.bodyHtml = currentHtml
    }

    onMounted(async () => {
      await reset()
    })

    return {
      state,
      userCount,
      disallowPast,
      onOk,
      onCancel,
      isFormValid,
      changeBody,
    }
  },
})
