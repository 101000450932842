




































































































import { computed, defineComponent, reactive } from "@vue/composition-api"
import RegistToEvent from "./Modal/RegistToEvent.vue"
import RegistToPlanner from "./Modal/RegistToPlanner.vue"
import RegistToNotification from "./Modal/RegistToNotification.vue"
import RegistToNextMemo from "./Modal/RegistToNextMemo.vue"
import { Editor, EditorMenuBubble } from "tiptap"
import { Meeting, MeetingStructure } from "@/models/Meeting"

interface State {
  eventOpen: boolean
  planOpen: boolean
  notificationOpen: boolean
  nextMemoOpen: boolean
  selectNode: object | null
  subject: string
}

export default defineComponent({
  components: {
    EditorMenuBubble,
    RegistToEvent,
    RegistToPlanner,
    RegistToNotification,
    RegistToNextMemo,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    editor: {
      type: Editor,
      required: true,
    },
    selectNode: {
      type: Object,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
    parent: {
      type: MeetingStructure,
      required: true,
    },
  },
  setup(props) {
    const editorValue = computed(() => props.editor)
    const state = reactive<State>({
      eventOpen: false,
      planOpen: false,
      notificationOpen: false,
      nextMemoOpen: false,
      selectNode: null,
      subject: "",
    })
    const setData = (selectNode: object) => {
      state.selectNode = selectNode
      state.subject = `${
        props.meeting.subject
      }(${props.meeting.startTime.toDateJpString()})の課題対応 `
    }
    const openEvent = () => {
      if (props.selectNode) {
        setData(props.selectNode)
        state.eventOpen = true
      }
    }
    const openTask = () => {
      state.subject = window.getSelection()?.toString() ?? ""
      state.planOpen = true
    }
    const openNotification = () => {
      if (props.selectNode) {
        setData(props.selectNode)
        state.notificationOpen = true
      }
    }
    const openNextMemo = () => {
      if (props.selectNode) {
        setData(props.selectNode)
        state.nextMemoOpen = true
      }
    }
    const insertText = (text: string) => {
      const currentHtml = props.editor.getHTML()
      props.editor.setContent(
        `${text}<p>＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>${currentHtml}`
      )
    }

    return {
      state,
      openEvent,
      openTask,
      openNotification,
      openNextMemo,
      editorValue,
      insertText,
    }
  },
})
