




































import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from "@vue/composition-api"
import CalendarMenu from "./CalendarMenu.vue"
import Dropdown, { Option } from "@/components/dropdowns/Dropdown.vue"
import DateTime from "@/models/DateTime"
import { ruleFunction } from "@/utilities/ruleFunction"

interface TimeOption extends Option {
  text: string
  value: string
}

interface State {
  date: string
  time: string
  errorMessage: string | boolean
}

export default defineComponent({
  components: {
    CalendarMenu,
    Dropdown,
  },
  props: {
    value: {
      type: DateTime,
      required: true,
    },
    rules: Array as PropType<ruleFunction[]>,
    isPersonalNotification: { type: Boolean, default: false },
    readonly: Boolean,
  },

  setup(props, { emit }) {
    const timeItems = _getDefaultTimeItems()
    const timeItem = _getDefaultStartTime(
      props.value,
      timeItems,
      props.isPersonalNotification
    )
    const state = reactive<State>({
      date: props.value.toDateStringSlash(),
      time: timeItem.value,
      errorMessage: true,
    })
    const conmibeTimeToDate = (date: Date, timeValue: string) => {
      const timeSplit = timeValue.split(":")
      date.setHours(Number(timeSplit[0]))
      date.setMinutes(Number(timeSplit[1]))
      date.setSeconds(0)
      return date
    }

    const calcError = (val: DateTime) => {
      if (!props.rules) return true
      let ret: boolean | string = true
      for (const rule of props.rules) {
        ret = rule(val)
      }
      return ret
    }

    const onTimeChanged = (timeValue: string) => {
      state.time = timeValue
      const dateTime = conmibeTimeToDate(new Date(state.date), state.time)
      const ret = new DateTime(dateTime)
      emit("input", ret)
      state.errorMessage = calcError(ret)
    }

    const onDateChanged = (date: string) => {
      state.date = date
      const dateTime = conmibeTimeToDate(new Date(state.date), state.time)
      const ret = new DateTime(dateTime)
      emit("input", ret)
      state.errorMessage = calcError(ret)
    }

    const reset = (newValue: DateTime) => {
      const date = newValue.toDateStringSlash()
      if (
        state.date !== date ||
        ("00000" + state.time).slice(-5) !== newValue.toJpTimeString()
      ) {
        state.date = date
        state.time = _getDefaultStartTime(
          newValue,
          timeItems,
          props.isPersonalNotification
        ).value
        const dateTime = conmibeTimeToDate(new Date(state.date), state.time)
        const ret = new DateTime(dateTime)
        emit("input", ret)
        state.errorMessage = calcError(ret)
      }
    }

    onMounted(() => {
      reset(props.value)
    })

    watch(
      () => props.value,
      newValue => {
        if (newValue) {
          reset(newValue)
        }
      }
    )

    return {
      state,
      timeItems,
      onTimeChanged,
      onDateChanged,
    }
  },
})

/**
 * 時間選択肢（select-list)を作成
 */
function _getDefaultTimeItems(): Array<TimeOption> {
  const times = [...Array(48)].map((_, i) => {
    const hour = Math.floor(i / 2)
    if (i % 2 === 0) {
      if (hour < 10) {
        return { text: `0${hour}:00`, value: `0${hour}:00` }
      } else {
        return { text: `${hour}:00`, value: `${hour}:00` }
      }
    } else {
      if (hour < 10) {
        return { text: `0${hour}:30`, value: `0${hour}:30` }
      } else {
        return { text: `${hour}:30`, value: `${hour}:30` }
      }
    }
  })
  return times
}

/**
 * デフォルトの開始時間index返す
 * 現在時刻より遅い時間をデフォルトの開始時間として表示するため。
 */

function _getDefaultStartTime(
  current: DateTime,
  defaultTimeItems: Array<TimeOption>,
  isPersonalNotification: boolean
): TimeOption {
  const nowHour = current.toJsDate().getHours()
  const nowMinutes = current.toJsDate().getMinutes()

  const index = defaultTimeItems.findIndex(
    t => Number(t.value.split(":")[0]) === nowHour
  )

  //左メニューのチャット通知確認は、登録された通知時刻と同じ時刻を表示する必要があるため
  if (isPersonalNotification) {
    if (nowMinutes > 29) {
      return defaultTimeItems[index + 1]
    } else {
      return defaultTimeItems[index]
    }
  }

  if (nowMinutes > 29) {
    return defaultTimeItems[index + 2]
  } else {
    return defaultTimeItems[index + 1]
  }
}
